import styled from "styled-components";
import { theme } from "../../constants";

export const AppContainer = styled.div`
  position: absolute;
  left: 50%;
  margin-top: 0px;
  margin-left: -480px;
  font-family: ${theme.font};
  width: 960px;
  font-size: 13px;
`;

export const AppContentContainer = styled.div`
  position: absolute;
  top: 89px;
  border: 1px solid #bfbfbf;
  background-color: white;
`;

export const HeaderContainer = styled.div`
  margin-top: -8px;
  padding: 0px;
  height: 80px;
`;

export const HeaderTitle = styled.div`
  color: ${theme.colors.dashboardTitle};
  font-size: 1.7em;
  letter-spacing: 3px;
`;

export const PageHeading = styled.h1`
  color: #c5651f
  font-size: 16px;
`;

export const TableContainer = styled.div`
  font-size: 13px;
  margin-top: 20px;
  padding-right: 20px;
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 50px;
  margin-right: 30px;
`;

export const Header = styled.thead`
  background-color: #dbe5c5;
`;

export const HeadCell = styled.th`
  padding: 4px;
  text-align: left;
  border: solid 1px #ccc;
`;

export const YellowCell = styled.td`
  padding: 4px;
  border: solid 1px #ccc;
  background-color: #f6fbe7;
`;

export const WhiteCell = styled.td`
  padding: 4px;
  text-align: right;
  border: solid 1px #ccc;
  background-color: #fff;
`;

export const SeashellCell = styled.td`
  padding: 4px;
  text-align: right;
  border: solid 1px #ccc;
  background-color: #fbf7f3;
`;

export const BoldText = styled.div`
  font-weight: bold;
  font-size: 13px;
`;

export const TextWrapper = styled.div`
  background-color: #f6fbe7;
  font-weight: bold;
  font-size: 14px;
  padding: 20px;
  margin-top: 72px;
  margin-right: 20px;
  white-space: nowrap;
  border: dashed 2px #d3d3d3;
`;
