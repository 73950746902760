import React, { Component } from "react";

import {
  Table,
  Header,
  HeadCell,
  YellowCell,
  WhiteCell,
  SeashellCell,
  TableContainer,
  BoldText
} from "../shared/styledComponents";

class SharedAuthenticationDashboardContent extends Component {
  state = {
    isLoading: true,
    sharedAuthenticationData: []
  };

  componentDidMount = () => {
    this.setState({
      isLoading: false,
      sharedAuthenticationData: [
        {
          name: "Expose Iden & Auth of existing system",
          totalJobs: 47,
          jobsDelivered: 34
        },
        {
          name: "Create coupling for new apps",
          totalJobs: 12,
          jobsDelivered: 7
        }
      ]
    });
  };
  render() {
    return (
      <div>
        <TableContainer>
          <BoldText>Shared Authentication</BoldText>
          <Table>
            <Header>
              <tr>
                <HeadCell />
                <HeadCell>Total Jobs</HeadCell>
                <HeadCell>Jobs Delivered</HeadCell>
              </tr>
            </Header>
            <tbody>
              {this.state.sharedAuthenticationData.map((items, index) => {
                return (
                  <tr key={index} style={{ cursor: "pointer" }}>
                    <YellowCell>{items.name}</YellowCell>
                    <WhiteCell>{items.totalJobs}</WhiteCell>
                    <SeashellCell>{items.jobsDelivered}</SeashellCell>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </TableContainer>
      </div>
    );
  }
}

export default SharedAuthenticationDashboardContent;
