import React, { Component } from "react";
import { Flex } from "rebass";
import moment from "moment";
import styled from "styled-components";

import { HeaderContainer, HeaderTitle } from "../shared/styledComponents";

import logo from "../../assets/icons/pwg-tree-logo-90-82.png";
import { DATE_FORMAT_LONG, theme } from "../../constants";

const DateDisplay = styled.div`
  background-color: ${theme.colors.dateBackground};
  vertical-align: center;
  padding: 5px;
  margin-left: 20px;
`;

const HeaderControls = styled.div`
  position: absolute;
  top: 12px;
  right: calc(50% - 480px);
  height: auto;
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
`;

class Header extends Component {
  render() {
    return (
      <HeaderContainer>
        <Flex alignItems="center">
          <img src={logo} alt={"logo"} />
          <HeaderControls>
            <HeaderTitle>PWG APPS</HeaderTitle>
            <DateDisplay ml={3} p={1}>
              {moment().format(DATE_FORMAT_LONG)}
            </DateDisplay>
          </HeaderControls>
        </Flex>
      </HeaderContainer>
    );
  }
}

export default Header;
