export const DATE_FORMAT_LONG = "dddd Do MMMM YYYY";

export const theme = {
  font: "Verdana, Arial, Tahoma, sans-serif",
  colors: {
    error: "#ff0000",
    primary: "#7a8e8d",
    heading: "#5D6797",
    gray2: "#aeaeae",
    border: "#aeaeae",
    toolTipBackground: "#dde6e7",
    pwg: "#858b1f",
    dateBackground: "#eff2de",
    dashboardTitle: "#545b21",
    sideMenu: {
      background: "#fffecb"
    },
    header: {
      tabBackground: "#e0e4c2",
      tabBorder: "#"
    }
  },
  zIndexes: {
    menu: 999
  }
};
