import React, { Component } from "react";
import { withRouter, Route, Switch } from "react-router-dom";
import { Flex } from "rebass";

import Header from "./components/header/header";
import AppsSideMenu from "./components/sideMenu/appsSideMenu";
import DisplayAllAppsDashboardContent from "./components/appsDashboardContent/displayAllAppsDashboardContent";

import {
  AppContainer,
  AppContentContainer
} from "./components/shared/styledComponents";

class App extends Component {
  render() {
    return (
      <AppContainer>
        <Header />
        <AppContentContainer>
          <Flex>
            <AppsSideMenu />
            <Switch>
              <Route
                path="/"
                exact={true}
                component={DisplayAllAppsDashboardContent}
              />
            </Switch>
          </Flex>
        </AppContentContainer>
      </AppContainer>
    );
  }
}

export default withRouter(App);
