import React, { Component } from "react";
import { Flex, Box } from "rebass";

import LeaveManagementDashboardContent from "./leaveManagementDashboardContent";
import DocumentManagementDashboardContent from "./documentManagementDashboardContent";
import SharedAuthenticationDashboardContent from "./sharedAuthenticationDashboardContent";
import { BoldText, TextWrapper } from "../shared/styledComponents";

class DisplayAllAppsDashboardContent extends Component {
  render() {
    return (
      <div>
        <Flex>
          <Box>
            <LeaveManagementDashboardContent />
            <DocumentManagementDashboardContent />
            <SharedAuthenticationDashboardContent />
            <BoldText>Map Leads & Orders</BoldText>
            <p>
              New map app displays geographic location of sales for each dealer.
              Can only be accessed using private login.
            </p>
          </Box>
          <Box>
            <TextWrapper>Some more info here</TextWrapper>
          </Box>
        </Flex>
      </div>
    );
  }
}

export default DisplayAllAppsDashboardContent;
