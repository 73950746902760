import { Link } from "react-router-dom";
import React from "react";
import { theme } from "../../constants";
import styled from "styled-components";
import { NavLink } from "rebass";

export const SideMenuDiv = styled.div`
  background-color: ${theme.colors.sideMenu.background};
  border-right: 1px solid #bfbfbf;
  height: 100%;
  font-size: 13px;
  min-width: 162px;
`;

export const SideMenuWhiteDiv = styled.div`
  background-color: #fff;
  border-bottom: 1px solid #989b82;
  margin-top: 0px;
  padding: 8px;
  padding-left: 15px;
`;

export const SubSideMenu = styled.div`
  display: none;
  position: absolute;
  min-width: 160px;
  padding: 12px 16px;
  z-index: 1;
`;
export const SideMenuLinkStyle = styled(Link)`
  white-space: nowrap;
  padding: 8px;
  padding-left: 15px;
  display: block;
  border-bottom: 1px solid #989b82;
  border-width: thin;
  border-color: gray;
  color: #202020;
  text-decoration: none;
  font-weight: normal;
  background-color: #e0e4c2;
  color: gray;
  &:hover {
    text-decoration: none;
  }
`;

export const SideMenuLink = props => {
  return (
    <SideMenuLinkStyle id={props.id} to={props.to} children={props.children} />
  );
};

export const Icon = styled.img`
  cursor: pointer;
`;

export const StyledSideMenu = styled.div`
  padding: 8px;
  padding-left: 15px;
  display: block;
  border-bottom: 1px solid #989b82;
  border-width: thin;
  border-color: gray;
  color: #202020;
  text-decoration: none;
  background-color: #c7cf93;
`;

export const StyledNavLink = styled(NavLink)`
  padding: 8px;
  padding-left: 15px;
  display: block;
  border-bottom: 1px solid #989b82;
  border-width: thin;
  border-color: gray;
  color: #202020;
  text-decoration: none;
  background-color: #e0e4c2;
  font-weight: normal;
  &:hover {
    text-decoration: underline;
  }
`;
