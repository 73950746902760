import React, { Component } from "react";
import { Flex, Box } from "rebass";

import { icons } from "../../assets/icons/icons";
import { BoldText } from "../shared/styledComponents";
import {
  StyledSideMenu,
  SideMenuLink,
  SideMenuDiv,
  SideMenuWhiteDiv,
  Icon,
  StyledNavLink
} from "./styledSideMenu";

class AppsSideMenu extends Component {
  state = {
    userFirstName: "Hans"
  };
  render() {
    return (
      <div>
        <SideMenuDiv style={{ marginRight: "20px" }}>
          <SideMenuWhiteDiv>
            <Flex>
              <Box width={9 / 10}>Welcome {this.state.userFirstName}</Box>
              <Box width={1 / 10}>
                <Icon src={icons["smileyFaceIcon"]} />
              </Box>
            </Flex>
          </SideMenuWhiteDiv>
          <StyledSideMenu>
            <BoldText>In Development</BoldText>
          </StyledSideMenu>
          <StyledNavLink href="https://lm.pwg-apps.co.uk">
            Leave Management
          </StyledNavLink>
          <SideMenuLink to="">Document Management</SideMenuLink>
          <SideMenuLink to="">Shared Authentication</SideMenuLink>
          <StyledSideMenu>
            <BoldText>Completed</BoldText>
          </StyledSideMenu>
          <StyledNavLink href="https://dashboard.thepwgroup.net/control/control_center_mw_dashboard.cgi">
            Mumford Dashboard
          </StyledNavLink>
          <SideMenuLink to="">Map Leads & Orders</SideMenuLink>
        </SideMenuDiv>
      </div>
    );
  }
}

export default AppsSideMenu;
